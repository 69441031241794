<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <div class="my-8">
      <h3 class="font-weight-bold black--text">
        Koreksi
      </h3>
    </div>
    <v-card class="pa-4">
      <v-container>
        <v-row class="align-center">
          <v-col
            cols="1"
            md="1"
            sm="2"
          >
            <div class="d-flex">
              <div v-if="examStudent.photo == null">
                <v-img
                  src="@/assets/images/avatars/1.png"
                  width="70"
                  class="rounded-pill"
                ></v-img>
              </div>
              <div v-else>
                <v-img
                  :src="examStudent.photo"
                  width="70"
                  class="rounded-pill"
                ></v-img>
              </div>
            </div>
          </v-col>
          <v-col
            cols="9"
            offset-md="0"
            offset-sm="0"
            offset="2"
            md="11"
            sm="10"
          >
            <div class="ml-2 ml-md-0">
              <h3 class="text-truncate">
                {{ examStudent.name }}
              </h3>
              <span>{{ examStudent.class }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center mb-5">
          <v-col
            cols="10"
            offset-md="1"
            offset-sm="2"
          >
            <v-row class="align-center">
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <h4>Status</h4>
                <v-chip
                  class="v-chip theme--light v-size--small v-chip-light-bg success--text"
                >
                  Sudah Mengumpul
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <h4>Waktu Pengerjaan</h4>
                <div class="d-flex align-center">
                  <div class="mr-1">
                    <v-img
                      src="@/assets/icons/calendar.svg"
                      width="18"
                    ></v-img>
                  </div>
                  <small>{{ examStudent.exam_deadline }}
                  </small>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <h4>Keterangan</h4>
                <div
                  v-if="examStudent.corrected == 'Sudah Dikoreksi'"
                  class="d-flex align-center"
                >
                  <div

                    class="mr-1"
                  >
                    <v-img
                      src="@/assets/icons/color/true.svg"
                      width="18"
                    ></v-img>
                  </div>

                  <small style="color: green">{{ examStudent.corrected }}</small>
                </div>
                <div
                  v-else
                  class="d-flex align-center"
                >
                  <div

                    class="mr-1"
                  >
                    <v-img
                      src="@/assets/icons/color/false.svg"
                      width="18"
                    ></v-img>
                  </div>

                  <small style="color: red">{{ examStudent.corrected }}</small>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <div class="mb-4">
              <h3>Soal Pilihan Ganda</h3>
              <span>Jawaban pilihan ganda akan terkoreksi otomatis oleh sistem</span>
            </div>
            <div>
              <v-card
                v-for="multipleOption in examMultiple"
                :key="multipleOption.uuid"
                outlined
                class="pa-4 mb-2"
              >
                <v-row class="align-center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <span>Soal No. {{ multipleOption.number }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <span>Jawaban : {{ multipleOption.answer_student }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <span>Jawaban yang benar: {{ multipleOption.answer_key }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <div>
                      <v-chip
                        class=""
                        :color="getColorChip(multipleOption.is_answer ==='Not Answered' ? 0 : 1)"
                      >
                        <v-img
                          :src="getIconChip(multipleOption.is_answer ==='Not Answered' ? 0 : 1)"
                          width="20"
                          height="20"
                          class="mr-2"
                        ></v-img> <span>{{ getTextChip(multipleOption.is_answer ==='Not Answered' ? 0 : 1) }}</span>
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <div>
                      <span><strong>{{ multipleOption.question_point }}</strong> Point</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <h3>Soal Essay</h3>
              <span>Jawaban essay akan dikoreksi manual oleh guru</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mb-4">
              <v-card
                v-for="(essay,index) in examEssay"
                :key="essay.uuid"
                outlined
                class="pa-4 mb-2"
              >
                <v-row class="align-center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <div>
                      <span>Soal No. {{ essay.number }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <div v-if="essay.answer_student !== 'Belum Menjawab'">
                      <div class="">
                        <v-avatar class="my-2 d-flex">
                          <v-img
                            alt="image"
                            class=""
                            :src="essay.answer_student"
                            @click="showOverlay(index)"
                          ></v-img>
                        </v-avatar>
                      </div>
                    </div>
                    <span v-else>Tidak menjawab</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-center">
                      <span class="mr-2">Masukkan Nilai:</span>
                      <v-text-field
                        v-model="essay.student_point"
                        outlined
                        dense
                        placeholder="Nilai"
                        class="mt-6 mr-2"
                        @keypress="numberValidation($event)"
                      ></v-text-field>
                      <span>Point</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col>
            <div>
              <h3>Total Nilai Pilihan Ganda dan Essay </h3>
              <h1>{{ examStudent.total_point_multiple }} + {{ examStudent.total_point_essay === null ? 0 : examStudent.total_point_essay }} = <span class="primary--text">{{ examStudent.total_point }}</span></h1>
            </div>
          </v-col>
        </v-row>
        <div>
          <div>
            <v-card-actions class="d-flex justify-center mb-0 mt-5">
              <v-btn
                color="primary"
                large
                :loading="isLoadingButton"
                :disabled="isLoadingButton"
                @click="saveCorrection()"
              >
                Simpan Koreksi
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                @click="redirect()"
              >
                Batal
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-container>
      <ImageOverlay
        :visible="overlay"
        :width="width"
      >
        <template v-slot:body>
          <v-card>
            <v-btn
              class="close-image"
              color="white"
              icon
              @click="overlay = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>

            <v-img
              :src="ovImage.ov"
              transition="scroll-y-transition"
              min-width="100"
              max-width="700"
            ></v-img>
          </v-card>
        </template>
      </ImageOverlay>
    </v-card>
  </div>
</template>

<script>
import iconFalse from '@/assets/icons/color/false.svg'
import iconTrue from '@/assets/icons/color/true.svg'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import { mdiClose, mdiImageMultipleOutline, mdiArrowLeft } from '@mdi/js'

export default {
  name: 'ElearningSubjectResultDetail',
  components: {
    ImageOverlay,
  },
  data() {
    return {
      ovImage: {
        ov: null,
      },
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
        mdiArrowLeft,
      },
      width: 600,
      isLoadingButton: false,
      absolute: true,
      overlay: false,
      iconTrue,
      iconFalse,
      examStudent: {},
      examMultiple: [],
      examEssay: [],
      correctionList: [
        {
          student_uuid: null,
          point_essay: 0,
          question_uuid: null,
        },
      ],
    }
  },
  mounted() {
    this.getQuestionStudent(this.$route.params.uuid, this.$route.params.uuidStudent)
  },
  methods: {
    returnBack() {
      return this.$router.back()
    },
    checkIcon(param) {
      if (param === 'Sudah Dikoreksi') {
        return '@/assets/icons/color/true.svg'
      }

      return '@/assets/icons/color/false.svg'
    },
    showOverlay(index) {
      this.overlay = !this.overlay
      this.ovImage.ov = this.examEssay[index].answer_student
    },
    numberValidation(evt) {
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }

      return true
    },
    async getQuestionStudent(uuid, uuidStudent) {
      await this.$services.ApiServices.getMultiple('exam', uuid, uuidStudent).then(
        ({ data }) => {
          this.examStudent = data.data
          this.examMultiple = this.examStudent.question.filter(item => item.is_essay === null)
          this.examMultiple.sort((a, b) => a.number - b.number)
          this.examEssay = this.examStudent.question.filter(item => item.is_essay)
          this.examEssay.sort((a, b) => a.number - b.number)
        },
        err => console.error(err),
      )
    },
    async saveCorrection() {
      this.isLoadingButton = true
      this.formData = new FormData()

      this.formData.append('student_uuid', this.$route.params.uuidStudent)
      await this.examEssay.forEach(async (item, index) => {
        this.formData.append('question_uuid[]', item.uuid)
        this.formData.append('point_essay[]', item.student_point)
      })
      await this.$services.ApiServices.add('teacher', this.formData).then(({ data }) => {
        this.showSnackbar({
          text: 'Add Point Essay has successfully',
          color: 'success',
        })
        this.isLoadingButton = false
      })
      await this.getQuestionStudent(this.$route.params.uuid, this.$route.params.uuidStudent)
    },
    valuePoint(index) {
      this.correctionList[index].point_essay = this.examEssay[index].student_point
    },
    redirect() {
      this.showSnackbar({
        text: 'Data mu berhasil disimpan',
        color: 'success',
      })
      this.$router.back()
    },
    getPhoto(url) {
      let urlPhoto
      if (url === null) {
        urlPhoto = '@/assets/images/avatars/1.png'

        return urlPhoto
      }

      return url
    },
    getTextChip(isCorrect) {
      if (isCorrect === 1) return 'Jawaban Benar'

      return 'Jawaban Salah'
    },
    getIconChip(isCorrect) {
      if (isCorrect === 1) return iconTrue

      return iconFalse
    },
    getColorChip(isCorrect) {
      if (isCorrect === 1) return 'v-chip theme--light v-size--default v-chip-light-bg success--text'

      return 'v-chip theme--light v-size--default v-chip-light-bg error--text'
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
